import React, {useContext, useState} from "react";
import {PlayerModal, LoginForm, PasswordForgotForm, RegisterForm, ProfileForm, Pages} from 'src/components'
import {Components} from 'eyecons-components'
import {AppContext} from "src/layouts";

export function PlayerLoginWindow() {
    const [page, setPage] = useState<{ page: Pages, locale?: string } | null>(null)
    const {mutateAuthenticated} = useContext(AppContext)
    if (!page) {
        return (
            <div
                className={'absolute z-20 inset-0 bg-gray-900/60 aspect-video backdrop-blur-sm flex flex-col justify-center items-center gap-10'}>
                <div className={'flex flex-col gap-2 w-full max-w-xs'}>
                    <Components.Buttons.Button type={'primary'} onClick={() => setPage({page: 'login'})}>
                        Registreren / Inloggen
                    </Components.Buttons.Button>
                </div>
            </div>
        )
    }

    return <PlayerModal onClose={() => setPage(null)}>
        {page.page === 'login' && <LoginForm setPage={setPage} onLogin={mutateAuthenticated}/>}
        {page.page === 'password-reset' && <PasswordForgotForm locale={page.locale || 'nl'} setPage={setPage}/>}
        {page.page === 'register' && <RegisterForm setPage={setPage} locale={page.locale || 'nl'}/>}
        {page.page === 'profile' && <ProfileForm locale={page.locale || 'nl'}/>}
    </PlayerModal>
}