import React, {useState} from 'react'

import {Components, Functions, Hooks} from 'eyecons-components'
import {useRouter} from "next/router";
import {PasswordForm, usePasswordInfo} from "src/components";
import {Request} from "eyecons-requests";

export type PasswordResetFormProps = {
    token: string
}
export  function PasswordResetForm({token}: PasswordResetFormProps) {
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState([])
    const [Locale, locale] = Hooks.useLocale()
    const [user, setUser] = useState({
        password: '',
        password_confirmation: '',
        token: token,
        email: ''
    })

    const [passwordInfo, setPasswordInfo, validate] = usePasswordInfo(user, locale)

    function submitPasswordReset() {
        if (!validate()) {
            return
        }

        setSubmitting(true)
        Request.fromApi('auth')
            .withErrors(setErrors)
            .post('/reset-password', user)
            .then((response: any) => {
                if (!response.errors) {
                    setSuccess(true)
                }

                setSubmitting(false)
            })
    }

    if (success) {
        return (
            <div className={'relative text-center'}>
                <Components.Texts.Heading type={'h5'} appendClassname={'text-dark mb-4'}>
                    {Functions.translate('Success!', locale)}
                </Components.Texts.Heading>

                <Components.Texts.Primary className={'text-gray-500'}>
                    {Functions.translate('You password has been successfully changed', locale)}
                </Components.Texts.Primary>

                <div className={'mt-8'}>
                    <Components.Buttons.Link
                        type={'primary'}
                        to={'/'}>
                        {Functions.translate('continue', locale)}
                    </Components.Buttons.Link>
                </div>
            </div>
        )
    }

    return <>
        <div className={'text-center mb-4 relative'}>
            <Components.Texts.Heading type={'h5'} appendClassname={'text-dark'}>
                <Locale/>
                {Functions.translate('Choose password', locale)}
            </Components.Texts.Heading>
        </div>

        <PasswordForm
            locale={locale}
            user={user}
            setUser={setUser}
            passwordInfo={passwordInfo}
            setPasswordInfo={setPasswordInfo}
        />

        <Components.Forms.Buttons
            type={'wide'}
            errors={errors}
            loading={submitting}
            title={Functions.translate('continue', locale)}
            submit={submitPasswordReset}
        />
    </>
}