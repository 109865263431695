import {Components} from 'eyecons-components'

export function AgeVerification({user, submitUser}: {user: any, submitUser: (user: any) => void}) {
    function submit(age_verification: boolean) {
        submitUser({...user, options: {
            ...user.options, age_verification, advertising_disabled: !age_verification,
        }});
    }

    return <>
        <div className={'max-w-md'}>
            <Components.Users.BettingHeader
                title={'Wat is je leeftijd?'}
                theme='white'
                link_text={'Waarom vragen we dit?'}
                link_url={'https://eyecons.zendesk.com/hc/nl/articles/16984132951313'}
            />

            <div className={'flex flex-col gap-2 sm:gap-3 mb-3.5 justify-center'}>
                <Components.Buttons.Button type={'white'} onClick={() => submit(true)}>
                    24 jaar of ouder
                </Components.Buttons.Button>
                <Components.Buttons.Button type={'dark'} outline onClick={() => submit(false)}>
                    Jonger dan 24 jaar
                </Components.Buttons.Button>
            </div>

            <Components.Texts.Primary className={'text-center text-xs text-white'}>
                Door je keuze te maken bevestig je dat je je bewust bent van de
                risico’s van online kansspelen en dat je momenteel niet bent
                uitgesloten van deelname aan kansspelen bij online kansspelaanbieders.
            </Components.Texts.Primary>
        </div>
    </>
}