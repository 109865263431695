import {Components, Functions} from 'eyecons-components'
import {useState} from "react";

export const PopularPasswords = [
    '11111111', '22222222', '33333333', '44444444', '55555555', '66666666', '77777777', '88888888', '99999999', '10000000', '12000000', '12300000', '12340000', '12345000', '12345600', '12345670', '12345678', '123456789', '1234567890', '987654321', 'password', 'password1', 'wachtwoord', 'qwerty123', 'qwertyuiop', 'iloveyou', 'starwars', 'passw0rd', 'whatever', '!@#$%^&*', 'aa123456', '18atcskd2w', '1q2w3e4r', '3rjs1la7qe', '1q2w3e4r5t',
]

type PasswordFormProps = {
    locale: string
    user: any
    setUser: (user: any) => void
    passwordInfo: any,
    setPasswordInfo: (passwordInfo: any) => void
}

export function usePasswordInfo(user: any, locale: string): [passswordInfo: any, setPasswordInfo: (passwordInfo: any) => void, () => boolean] {
    const [passwordInfo, setPasswordInfo] = useState({
        message: '',
        level: {},
    })

    function validate() {
        setPasswordInfo({...passwordInfo, message: ''})
        if (user.password !== user.password_confirmation) {
            setPasswordInfo({...passwordInfo, message: Functions.translate('Password confirmation incorrect.', locale)})
            return false
        }

        if (user.password.length < 8) {
            setPasswordInfo({...passwordInfo, message: Functions.translate('Pick a longer password.', locale)})
            return false
        }

        if (PopularPasswords.includes(user.password)) {
            setPasswordInfo({
                ...passwordInfo,
                message: Functions.translate('Pick a stronger password. Use a combination of letters, numbers and symbols.', locale),
            })
            return false
        }

        return true
    }

    return [passwordInfo, setPasswordInfo, validate]
}

export function PasswordForm ({passwordInfo, setPasswordInfo, user, setUser, locale}: PasswordFormProps) {
    function checkPassword (password: string) {
        setUser({...user, password})

        if (PopularPasswords.includes(password) || user.password.length < 8) {
            return setPasswordInfo({
                ...passwordInfo, level: {
                    width: '33%',
                    backgroundColor: '#e0125b',
                },
            })
        }

        if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            return setPasswordInfo({
                ...passwordInfo,
                level: {
                    width: '66%', backgroundColor: '#f8aa1c',
                },
            })
        }

        return setPasswordInfo({
            ...passwordInfo,
            level: {
                width: '100%', backgroundColor: '#aac712',
            },
        })
    }

    return <>
        <Components.Forms.Field>
            <Components.Forms.Input
                type={'email'}
                placeholder={Functions.translate('E-mail address', locale)}
                onChange={(email: string) => setUser({...user, email})}
                value={user.email}
            />
        </Components.Forms.Field>

        <Components.Forms.Field>
            <Components.Forms.Input
                className={'mb-2'}
                type={'password'}
                placeholder={Functions.translate('Choose password', locale)}
                value={user.password}
                onChange={(value: string) => checkPassword(value)}
            />
            {passwordInfo.level.width &&
                <div className={'mb-5 w-full'}>
                    <div className={'h-2'} style={passwordInfo.level}/>
                </div>
            }

        </Components.Forms.Field>

        <Components.Forms.Field>
            <Components.Forms.Input
                type={'password'}
                placeholder={Functions.translate('Repeat password', locale)}
                value={user.password_confirmation}
                onChange={(password_confirmation: string) => setUser({...user, password_confirmation})}
            />

            {passwordInfo.message !== '' && <Components.Forms.Errors errors={[{message: passwordInfo.message}]}/>}
        </Components.Forms.Field>
    </>
}

