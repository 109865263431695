import {Components} from 'eyecons-components'

export function Birthday({user, submitUser}: {user: any, submitUser: (user: any) => void}) {
    function submit(age_verification: boolean) {
        submitUser({ ...user.data, options: {
            ...user.options, age_verification, advertising_disabled: !age_verification,
        }});
    }

    return <>
        <Components.Users.BettingHeader title={'Wat is je leeftijd?'} theme={'consent'} />

        <Components.Texts.Primary className={'inline text-sm text-gray-500'}>
            Om content en uitingen over online kansspelen volgens wet- en
            regelgeving te tonen, willen we zeker weten dat je oud genoeg bent. Je
            kan hoe dan ook onze website bezoeken,{' '}
            <Components.Texts.Link href={'https://eyecons.zendesk.com/hc/nl/articles/16984132951313'} target={'_blank'}>
                klik hier
            </Components.Texts.Link>
            {' '}
            voor meer informatie.
        </Components.Texts.Primary>

        <div className="mt-6 mb-4">
            <Components.Forms.Buttons
                type={'wide'}
                button_type={'dark'}
                title={'24 jaar of ouder'}
                submit={() => submit(true)}
                errors={user.errors}
            />

            <Components.Forms.Buttons
                type={'wide'}
                button_type={'dark'}
                title={'Jonger dan 24 jaar'}
                submit={() => submit(false)}
                errors={user.errors}
            />
        </div>

        <Components.Texts.Primary className={'text-xs text-left text-gray-500'}>
            Door je keuze te maken bevestig je dat je je bewust bent van de risico’s
            van online kansspelen en dat je momenteel niet bent uitgesloten van
            deelname aan kansspelen bij online kansspelaanbieders.
            {user.id && (
                <>
                    Je kunt je instellingen wijzigen in je
                    {' '}
                    <Components.Texts.Link href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}/profile`}>profiel</Components.Texts.Link>
                    .
                </>
            )}
        </Components.Texts.Primary>
    </>
}