import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "src/layouts";
import classNames from "classnames";
import {Components, Hooks} from 'eyecons-components'
import {Container} from "src/components";
import {Video, Channel, Request} from 'eyecons-requests'
import Link from "next/link";
import {useRouter} from "next/router";

export function Search() {
    const router = useRouter()
    const [queryParams, setQueryParams] = useState<any>({})
    const [videos, setVideos] = useState<Array<Video>>([])
    const [channels, setChannels] = useState<Array<Channel>>([])
    const {searching, setSearching} = useContext(AppContext)
    const [search, setSearch] = Hooks.useSearch(queryParams, setQueryParams)

    function closeSearch() {
        setSearch('')
        setSearching(false)
    }

    useEffect(() => {
        if (queryParams?.search) {
            Request.fromApi('backend', true)
                .get('/videos', {
                    ...queryParams,
                    paginate: 20,
                    sort: 'created_at desc',
                    resource: 'VideoFull',
                })
                .then(response => {
                    setVideos(response.data)
                })

            Request.fromApi('backend', true)
                .get('/channels', {
                    ...queryParams,
                    paginate: 5,
                })
                .then(response => {
                    setChannels(response.data)
                })
        } else {
            setVideos([])
            setChannels([])
        }
    }, [queryParams?.search]);

    useEffect(() => {
        if (queryParams.search) {
            closeSearch()
        }
    }, [router.pathname, router.query]);

    return (
        <div className={classNames('bg-white fixed z-40 inset-0 pt-[60px] overflow-y-scroll', {
            'hidden': !searching
        })}>
            <Container size={'small'} className={'flex flex-col gap-8'}>
                <div className={'relative'}>
                    <input
                        className={'border-b-2 border-b-gray-400 focus:border-b-dark w-full px-4 py-2 text-xl focus:outline-none'}
                        value={search}
                        placeholder={'Zoeken...'}
                        onChange={event => setSearch(event.target.value)}
                    />
                    <button className={'absolute right-0 top-2'} onClick={closeSearch}>
                        <Components.Icons.Icon name={'x'} className={'w-8 h-8 text-gray-400'}/>
                    </button>
                </div>

                <div className={'flex flex-col gap-2'}>
                    {channels.map((channel, index) => (<Link key={index} href={`/kanalen/${channel.name}`} className={'flex gap-4 items-center'}>
                        <img className={'w-[60px] h-[60px] rounded-full object-cover'} src={channel.logo} alt={channel.label}/>
                        <Components.Texts.Heading type={'h5'}>{channel.label}</Components.Texts.Heading>
                    </Link>))}
                </div>

                <div className={'flex flex-col gap-2'}>
                    {videos.map((video, index) => {
                        return <Components.Videos.Card variant={'fixed'} key={index} video={video}/>;
                    })}
                </div>
            </Container>
        </div>
    )
}