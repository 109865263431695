import React, {useContext, useEffect, useState} from 'react'
import {AppContext, VideoContext} from "src/layouts";
import {Request} from "eyecons-requests";
import {PlayerPaymentWindow, PlayerLoginWindow, PlayerVod, PlayerLive} from "src/components";

type PlayerPaymentProps = {
    is_live?: boolean,
    autoplay?: boolean,
    paid: boolean
    setPaid: (paid: boolean) => void
}

export function PlayerPayment ({is_live, autoplay = false, paid, setPaid}: PlayerPaymentProps) {
    const [loading, setLoading] = useState(true)
    const {authenticated, authenticatedIsLoading} = useContext(AppContext)
    const {video} = useContext(VideoContext)

    const playable = video.status === 'published' && paid

    useEffect(() => {
        if (authenticatedIsLoading) {
            return
        }

        if (authenticated) {
            Request.fromApi('ecommerce').get( `/customers/${authenticated.id}/paid/${video.route}/${video.id}`).then(response => {
                if (response.data) {
                    setPaid(response.data.owns)
                }

                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [authenticated, authenticatedIsLoading])

    function renderPaymentWindow() {
        if (paid || video.status !== 'published') {
            return null
        }

        if (authenticated) {
            return <PlayerPaymentWindow/>
        }

        return <PlayerLoginWindow/>
    }

    if (loading) {
        return null
    }

    return <>
        {renderPaymentWindow()}

        {is_live ? <PlayerLive
            autoplay={autoplay}
            playable={playable}
            paid={paid}
        /> : <PlayerVod
            autoplay={autoplay}
            playable={playable}
        />}
    </>
}
