import React from "react";
import {Components} from 'eyecons-components'

type PlayerModalProps = {
    children: React.ReactNode
    onClose?: () => void
}

export function PlayerModal({children, onClose}: PlayerModalProps) {
    return (
        <div
            className={'fixed inset-0 top-[62px] md:absolute z-[1001] flex flex-col justify-center items-center'}>
            <div className={'bg-white p-10 max-w-xl mx-auto w-full h-full sm:h-auto relative shadow-md overflow-y-scroll'}>
                {onClose && <button className={'absolute top-2 right-2'} onClick={onClose}>
                    <Components.Icons.Icon name={'x'} className={'h-6 w-6 text-gray-700'}/>
                </button>}

                {children}
            </div>
        </div>
    )
}